/* General Start */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(230, 230, 230) !important;

  background-image: url("images/grey-background-image.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
@media (max-width: 750px) {
  body {
    background-image: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue", cursive;
  color: #373737;
}

a {
  text-decoration: none;
}

p {
  font-family: "Quicksand", sans-serif;
  color: #373737;
}

/* General End */

/* Header Start */

.desktopHeaderContainer,
.mobileHeaderContainer {
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
  position: sticky;
  top: 0;
  z-index: 500;
  padding: 10px 0px 10px 0px;
}
.mainHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.desktopHeaderContainer h2,
.mobileHeaderContainer h2 {
  color: rgb(230, 230, 230);
}

.headerMainLogoContainer {
  width: 27vw;
  max-width: 170px;
  margin: 20px 0px 15px 0px;
}

.headerMainLogo {
  width: 100%;
  height: auto;
  filter: invert(1);
  margin: 0px;
}

.mobileHeaderContainer {
  flex-direction: column;
}

.mobileHeaderLinks {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.socialMediaLinksContainer {
  display: flex;
  justify-content: center;
}
.socialMediaLinksContainer svg {
  fill: rgb(255, 255, 255);
  /* Icons from: https://www.iconfinder.com/social-media-icons */
}

.socialMediaLinksContainer svg:hover {
  fill: black;
  border: 1px dashed rgb(230, 230, 230);
  border-radius: 10%;
}

.socialMediaLinksContainer a {
  max-height: 40px;
  max-width: 40px;
  margin: 0px 15px 0px 15px;
}

.galleryButton {
  color: rgb(0, 0, 0);
  background-color: rgb(230, 230, 230);
  padding: 5px 30px 5px 30px;
  margin: 10px 0px 10px 0px;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 1px;
  cursor: pointer;
  border: 1px dashed rgb(230, 230, 230);
}
.formSubmitButton {
  color: rgb(0, 0, 0);
  background-color: inherit;

  padding: 5px 30px 5px 30px;
  margin: 10px 0px 10px 0px;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 1px;
  cursor: pointer;
  border: 1px dashed black;
}
.galleryButton:hover,
.formSubmitButton:hover {
  color: rgb(230, 230, 230);
  background-color: black;
  font-weight: 700;
}

@media (max-width: 750px) {
  .socialMediaLinksContainer a {
    max-height: 30px;
    max-width: 30px;
    margin: 0px 6px 0px 6px;
  }
  .galleryButton {
    padding: 4px 12px 4px 12px;
    font-size: 1.1rem;
    margin: 5px 0px 5px 0px;
  }
}

/* Header End */

/* Main Start */

.homePageTitleContainer {
  margin: 20px 10px 0px 10px;
}
.homePageTitleSubText {
  font-size: 2.5rem;
  text-align: center;
  margin: 0px;
}
.homePageTitleText {
  font-family: "Maven Pro", sans-serif;
  letter-spacing: -2px;
  font-size: 2rem;
  text-align: center;
}

.homePageBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homePageBoxContainerTop a,
.homePageBoxContainerBottom a {
  margin: 10px 0px 10px 0px;
  text-decoration: none;
}

.homePageBoxContainerTop,
.homePageBoxContainerBottom {
  display: flex;
  flex-direction: column;
}
.homePageBox {
  background-color: grey;
  width: 80vw;
  min-height: 140px;
}
.homePageBoxAbstract {
  background-image: url("images/OtherImages/AbstractHomePage.JPG");
  background-repeat: no-repeat;
  background-size: cover;
}
.homePageBoxAbstract:hover {
  background-image: url("images/OtherImages/BlackSquareOne.jpg");
}
.homePageBoxFigurative {
  background-image: url("images/OtherImages/FigurativeHomePage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.homePageBoxFigurative:hover {
  background-image: url("images/OtherImages/BlackSquareTwo.jpg");
}
.homePageBoxAbout {
  background-image: url("images/OtherImages/AboutSamTwo.JPG");
  background-position: 50% 30%;

  background-size: cover;
}
.homePageBoxAbout:hover {
  background-image: url("images/OtherImages/BlackSquareThree.jpg");
}
.homePageBoxContact {
  background-image: url("images/contactSam.JPG");
  background-position: 50% 30%;
  background-size: cover;
}
.homePageBoxContact:hover {
  background-image: url("images/OtherImages/BlackSquareFour.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.homePageBox:hover {
  background-color: black;
  color: rgb(230, 230, 230);
  text-shadow: none;
}

.homePageBox {
  color: black;
  padding: 0px 0px 0px 0px;
}
.homePageBox:hover .homePageBoxTitle {
  color: rgb(230, 230, 230);
  text-shadow: none;
}
.homePageBoxTitle {
  color: black;
  font-size: 1.8rem;
  padding: 10px 1px 0px 10px;
  margin: 0px;
  text-shadow: 0px 0px 10px rgb(230, 230, 230);
}

@media (min-width: 750px) {
  .homePageBoxContainerTop,
  .homePageBoxContainerBottom {
    flex-direction: row;
  }
  .homePageBox {
    width: calc(200px + 20vw);
    height: calc(150px + 15vw);
  }
  .homePageBoxContainerTop a,
  .homePageBoxContainerBottom a {
    margin: 20px;
  }
  .homePageBoxTitle {
    color: black;
    font-size: 2.5rem;
    padding: 20px 1px 0px 10px;
    margin: 0px;
    text-shadow: 0px 0px 15px rgb(230, 230, 230);
  }
  .homePageTitleSubText {
    font-size: 3rem;
  }
}

@media (max-width: 750px) {
  .homePageTitleContainer {
    margin: 5px 3px 0px 3px;
  }
  .homePageTitleText {
    font-size: 1.5rem;
    text-align: center;
    margin: 2px 12px 2px 12px;
  }
}

/* Main End */

/* Gallery Start */
.infoPageSubHeadingAbs {
  text-align: center;
  height: 180px;
  margin: auto;
  background-image: url("images/OtherImages/BlackBrushOne.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.infoPageSubHeadingAbsText {
  font-weight: 600;
  margin: auto;
  color: rgb(230, 230, 230);
  max-width: 310px;
  padding: 25px 5px 0px 5px;
}
.infoPageSubHeadingCity {
  text-align: center;
  height: 230px;
  margin: auto;
  background-image: url("images/OtherImages/BlackBrushTwo.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.infoPageSubHeadingTitle h1 {
  font-size: 3rem;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
}

.infoPageSubHeadingCityText {
  font-weight: 600;
  margin: auto;
  color: rgb(230, 230, 230);
  padding: 33px 5px 30px 5px;
  max-width: 310px;
}

.artAndTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px 0px 10px 0px;
  border: 1px dashed grey;
}
.artSliderContainer {
  width: 80vw;
  margin: 0px;
}

.galleryTitleBox {
  background-color: black;
  color: rgb(230, 230, 230);
  position: sticky;
}
.galleryTitle {
  color: rgb(230, 230, 230);
  text-align: center;
  padding: 3px 0px 3px 0px;
}

.artTitleText h3 {
  font-size: 2.5rem;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-shadow: 0px 0px 10px rgb(230, 230, 230);
}
.artInfoText p {
  font-size: 1.2rem;
  text-align: center;
  margin: 0px 0px 0px 0px;
  text-shadow: 0px 0px 10px rgb(230, 230, 230);
}

.artTextOnlyContainer {
  margin: 0px 5px 10px 5px;
}
@media (min-width: 750px) {
  .galleryTitle {
    font-size: 2.5rem;
  }
  .infoPageSubHeadingTitle h1 {
    font-size: 4rem;
  }

  .infoPageSubHeadingAbs {
    background-size: contain;
    height: 220px;
  }
  .infoPageSubHeadingAbsText {
    font-size: 1.2rem;
    max-width: 550px;
    padding: 50px 30px 0px 30px;
  }

  .infoPageSubHeadingCity {
    background-size: contain;
    height: 270px;
  }
  .infoPageSubHeadingCityText {
    font-size: 1.2rem;
    max-width: 450px;
    padding: 40px 45px 40px 45px;
  }
  .artAndTextContainer {
    flex-direction: row-reverse;
    border: none;
  }

  .artTextOnlyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0px 0px 50px;
    width: 25vw;
  }
  .artSliderContainer {
    width: 50vw;
    max-width: 400px;
  }
  .artTitleText h3,
  .artInfoText p {
    text-align: left;
    padding-bottom: 5px;
  }
  .artTitleText h3 {
    font-size: 2.8rem;
  }
  .artInfoText p {
    font-size: 1.6rem;
  }
}
@media (min-width: 1200px) {
  .galleryTitle {
    font-size: 3rem;
  }
  .infoPageSubHeadingTitle h1 {
    font-size: 6rem;
  }

  .infoPageSubHeadingAbs {
    height: 280px;
  }
  .infoPageSubHeadingCity {
    height: 360px;
  }

  .infoPageSubHeadingAbsText {
    max-width: 680px;
    padding: 60px 40px 0px 40px;
  }
  .infoPageSubHeadingCityText {
    max-width: 600px;
    padding: 60px 40px 100px 40px;
  }

  .infoPageSubHeadingAbsText,
  .infoPageSubHeadingCityText {
    font-size: 1.6rem;
  }

  .artTitleText h3 {
    font-size: 3.2rem;
  }
  .artInfoText p {
    font-size: 1.8rem;
  }
}

/* Gallery End */

/* About Sam Start */

.aboutSamTextAndPhotoDivOne,
.aboutSamTextAndPhotoDivTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
}
.aboutSamTextDivOne,
.aboutSamTextDivTwo {
  font-weight: 600;
  text-shadow: 0px 0px 5px rgb(230, 230, 230);
  margin-top: 10px;
  max-width: 450px;
}
.aboutSamTextDivOne p:last-child {
  margin-bottom: 0px;
}

.AboutSamPagePhotoOneContainer img,
.AboutSamPagePhotoTwoContainer img {
  width: 100%;
  max-width: 400px;
  transform: scaleX(-1);
}

@media (min-width: 750px) {
  .aboutSamTextAndPhotoDivOne {
    flex-direction: row;
    justify-content: center;
  }

  .aboutSamTextAndPhotoDivTwo {
    flex-direction: row-reverse;
    justify-content: center;
  }
  .aboutSamTextDivOne,
  .aboutSamTextDivTwo {
    width: 500px;
  }
  .AboutSamPagePhotoOneContainer,
  .AboutSamPagePhotoTwoContainer {
    max-width: 500px;
    min-width: 350px;
    margin: 20px;
  }
}

/* About Sam End */

/* Contact Page Start */

.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.contactPage p,
.formInputContainer {
  text-shadow: 0px 0px 10px rgb(230, 230, 230);
  font-weight: 600;
  text-align: center;
}

.contactForm,
.formInputContainer input,
.formInputContainer textarea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px 10px 5px;
  border: 1px dashed grey;
  padding: 10px;
}
.formInputContainer {
  display: flex;
  flex-direction: column;
}
.formInputOverallContainer {
  margin-bottom: 10px;
}

@media (min-width: 750px) {
  .contactForm {
    border: 1px dashed grey;
    padding: 10px;
  }
  .nameInputContainer {
    display: flex;
    flex-direction: row;
  }
}

/* Contact Page End */

/* Footer Start */

.copyrightText {
  text-align: center;
  padding: 20px 0px 20px 0px;
  margin: 0px;
}

/* Footer End */

.loader {
  text-align: center;
  margin: auto;
  width: 100vw;
  margin-top: 3rem;
}

.loader img {
  height: auto;
  width: 90%;
  animation: loadAnimation 1.5s infinite;
}

@media (min-width: 750px) {
  .loader {
    margin-top: 1rem !important;
  }

  .loader img {
    width: 50%;
  }
}

@keyframes loadAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: rotate(1);
  }
}
